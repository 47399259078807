var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.preparationComplete
    ? _c(
        "div",
        { staticClass: "ClientDocs column" },
        [
          _vm._l(_vm.standardDocuments.concat(_vm.otherDocuments), function(
            document,
            index
          ) {
            return _c(
              "div",
              { key: index, staticClass: "ClientDocs__document column" },
              [
                _c(
                  "client-doc",
                  {
                    staticClass: "mt-22 mb-22",
                    attrs: {
                      client: _vm.client,
                      document: document,
                      "filestack-client": _vm.filestackClient,
                      "filestack-picker-options": _vm.filestackPickerOptions
                    },
                    on: { deleted: _vm.getClientDocs }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(document.technical_name || document.name) +
                        "\n    "
                    )
                  ]
                ),
                _c("hr", { staticClass: "Divider" })
              ],
              1
            )
          }),
          _vm.client.status === "pending"
            ? _c(
                "base-button",
                {
                  staticClass: "bg-blue fc-white mt-30",
                  on: { click: _vm.createNewDocument }
                },
                [_vm._v("\n    Upload Additional Document\n  ")]
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    v-if="preparationComplete"
    class="ClientDocs column"
  >
    <div
      v-for="(document, index) in [...standardDocuments, ...otherDocuments]"
      :key="index"
      class="ClientDocs__document column"
    >
      <client-doc
        @deleted="getClientDocs"
        class="mt-22 mb-22"
        :client="client"
        :document="document"
        :filestack-client="filestackClient"
        :filestack-picker-options="filestackPickerOptions"
      >
        {{ document.technical_name || document.name }}
      </client-doc>

      <hr class="Divider">
    </div>

    <base-button
      v-if="client.status === 'pending'"
      @click="createNewDocument"
      class="bg-blue fc-white mt-30"
    >
      Upload Additional Document
    </base-button>
  </div>
</template>

<script>
// Packages
import * as filestack from 'filestack-js'
// Helpers
import { ClientDocument } from '../../../utils/api'
// Packages
import BaseButton from '../../../components/base-button.vue'
import ClientDoc from './client-doc.vue'

export default {
  name: 'ClientDocs',

  components: {
    BaseButton,
    ClientDoc,
  },

  created () {
    this.filestackClient = filestack.init(this.filestackAPIKey)
    this.getClientDocs()
  },

  data () {
    return {
      documents: null,
      filestackAPIKey: 'ACrLnuwGBRv2dzbEzOiWQz',
      filestackClient: null,
      filestackPickerOptions: {
        accept: '.pdf',
        allowManualRetry: true,
        // TODO: waiting on 'gmail' verification
        fromSources: ['local_file_system', 'googledrive', 'box'],
        maxFiles: 5,
      },
      standardDocuments: [
        {
          technical_name: 'notice of assignment',
          type: 'assignment letter',
          uploaded: false,
        },
        {
          technical_name: 'factoring agreement',
          type: 'factoring agreement',
          uploaded: false,
        },
        {
          technical_name: 'w-9',
          type: 'w9',
          uploaded: false,
        },
        {
          technical_name: 'certificate of insurance',
          type: 'certificate of insurance',
          uploaded: false,
        },
        {
          technical_name: 'filed UCC',
          type: 'filed ucc',
          uploaded: false,
        },
        {
          technical_name: 'lien snapshot',
          type: 'lien snapshot',
          uploaded: false,
        },
        {
          technical_name: 'corporation documents',
          type: 'articles of incorporation',
          uploaded: false,
        },
        {
          technical_name: 'form 8821',
          type: 'form 8821',
          uploaded: false,
        },
        {
          technical_name: 'MC Authority',
          type: 'mc authority',
          uploaded: false
        },
        {
          technical_name: 'Release Letter',
          type: 'release letter',
          uploaded: false
        }
      ],
      otherDocuments: [],
      preparationComplete: false,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    }
  },

  methods: {
    compareDocuments (documents) {
      // Compare received documents with mandatory documents
      // Updates mandatory documents and adds in document properties for UI purposes
      documents.forEach(doc => {
        this.standardDocuments.forEach((manDoc, index) => {
          if (manDoc.type === doc.document_type) {
            this.$set(this.standardDocuments[index], 'uploaded', true)
            Object.assign(this.standardDocuments[index], doc)
          }
        })
        // If some other document_type
        if (doc.document_type === 'other') {
          this.otherDocuments.push({ ...doc, uploaded: true })
        }
      })

      this.preparationComplete = true
    },

    async createNewDocument () {
      this.fileStackUpload(
        async (newDoc) => {
          this.progressStart()

          try {
            const doc = (await ClientDocument.create({
              client_id: this.client.id,
              document_type: 'other',
              name: newDoc.filename,
              url: newDoc.url,
            })).data
            this.otherDocuments.push({
              ...doc,
              technical_name: doc.name,
              uploaded: true
            })
            this.progressFinish()
          }
          catch (error) {
            this.captureSentryEvent(
              'Client Docs "Create"',
              {
                config: error.config,
                data: this.$data,
                details: error,
                newDocFromFileStackUpload: newDoc || null,
                props: this.$props,
                response: error.response,
              }
            )
            this.CError(error)
            this.requestFailure({ message: 'There was an issue updating the document' })
          }
        }
      )
    },

    fileStackUpload (tryFn) {
      this.filestackClient.picker({
        ...this.filestackPickerOptions,
        onUploadDone: (res) => {
          res.filesUploaded.forEach(file => {
            tryFn(file)
          })
        }
      }).open()
    },

    async getClientDocs () {
      try {
        this.documents = (await ClientDocument.queryList({ client_id: this.client.id })).data.rows
        this.compareDocuments(this.documents)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Docs "Get Docs"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s documents' })
      }
    }
  }
}
</script>

<style lang="sass">
.ClientDocs
  padding: 0 rem(29px) 2.5rem

  &__document

    &:last-child
      .Divider
        display: none
</style>

<template>
  <div class="ClientDoc column">
    <div class="row">
      <!-- document label -->
      <div class="row row--align-center">
        <i
          v-if="currentDoc.created_at"
          class="fa fa-check fa-15 fa-success"
        />
        <i
          v-if="!currentDoc.created_at"
          class="fa fa-add fa-rotate-45 fa-20 fa-danger"
        />

        <label class="fs-16 fw-med capitalize">
          <slot />
        </label>
      </div>

      <!-- icons and date -->
      <div class="row row--align-center row--justify-end">
        <div
          v-if="currentDoc.id"
          class="row row--align-center row--width-auto clickable"
        >
          <i
            @click="viewDocument"
            class="fa fa-view fa-15 fc-light mr-20"
            role="button"
          />
          <i
            @click="updateDocument"
            class="fa fa-upload-2 fa-15 fc-light mr-20"
            role="button"
          />
          <i
            @click="showEmail"
            class="fa fa-mail fa-15 fc-light mr-20"
            role="button"
          />
          <i
            @click="deleteDoc"
            class="fa fa-trash-can fa-15 fc-light mr-20"
            role="button"
          />
        </div>

        <i
          v-else
          @click="createNewDocument"
          class="fa fa-upload-2 fa-15 fc-light mr-20 clickable"
        />
        <time
          class="ClientDocs__doc-date"
          :datetime="currentDoc.updated_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
        >
          {{ currentDoc.updated_at | datetime('MM/DD/YYYY') }}
        </time>
      </div>
    </div>

    <!-- Sending document as email (footer) -->
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="emailing"
        class="row row--align-end mt-12"
      >
        <i
          @click="cancelEmailing"
          @keyup.enter="cancelEmailing"
          class="ClientDoc__close-icon fa fa-add-circle fa-rotate-45 fa-20 fa-3 clickable"
          tabindex="0"
        />
        <div class="row row--align-end row--wrap">
          <label class="ClientDoc__email-lbl fs-14 mr-9">Who do you want to send this invoice to?</label>
          <base-input
            v-model.trim="email.value"
            @keydown.enter="sendDocument"
            :instructions="instructions.EMAIL_INSTRUCTION"
            :label="true"
            :label-for="'email'"
            :placeholder="'email'"
            :valid="email.valid"
          >
            Email
          </base-input>
          <base-button
            @click="sendDocument"
            :disabled="(!email && !client.email) || buttonDisabled"
            class="bg-blue fc-white ml-6"
          >
            Send
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import { ClientDocument } from '../../../utils/api'
import { viewDocument } from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseInput from '../../../components/base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../../utils/validation-mixin'

export default {
  name: 'ClientDoc',

  components: {
    BaseButton,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    document: {
      type: Object,
      required: true,
    },
    filestackClient: {
      type: Object,
      required: true,
    },
    filestackPickerOptions: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      buttonDisabled: false,
      currentDoc: this.document,
      email: {
        valid: true,
        value: '',
      },
      emailing: false,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    cancelEmailing () {
      this.emailing = false
      this.email.valid = true
      this.email.value = ''
    },

    async createNewDocument () {
      this.fileStackUpload(
        async (newDoc) => {
          try {
            const doc = (await ClientDocument.create({
              client_id: this.client.id,
              document_type: this.currentDoc.type,
              name: newDoc.filename,
              url: newDoc.url,
            })).data

            this.currentDoc = doc
          }
          catch (error) {
            this.captureSentryEvent(
              'Client Doc "Create New"',
              {
                config: error.config,
                data: this.$data,
                details: error,
                newDocFromFilestackUpload: newDoc || null,
                props: this.$props,
                response: error.response,
              }
            )
            this.CError(error)
            this.requestFailure({ message: 'There was an issue updating the document' })
          }
        }
      )
    },

    async deleteDoc () {
      this.progressStart()

      try {
        await ClientDocument.delete(this.currentDoc.id)
        this.$emit('deleted')
        this.currentDoc = { type: this.currentDoc.document_type }
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Doc "Delete"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue deleting the document' })
      }
    },

    fileStackUpload (tryFn) {
      this.filestackClient.picker({
        ...this.filestackPickerOptions,
        onUploadDone: (res) => {
          res.filesUploaded.forEach(file => {
            tryFn(file)
          })
        }
      }).open()
    },

    async sendDocument () {
      if (!this.validateEmail()) return

      this.buttonDisabled = true
      this.progressStart()
      try {
        await ClientDocument.sendDocument({
          email: this.email.value,
          id: this.currentDoc.id,
        })
        this.emailing = false
        this.$set(this.email, 'value', '')
        this.requestSuccess({ message: 'Email sent' })
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Doc "Send"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: `There was an issue sending the document to ${this.email.value}`
        })
      }
      this.buttonDisabled = false
      this.emailing = false
      this.email.value = ''
    },

    showEmail () {
      this.emailing = true
      this.email.value = this.client.email
    },

    async updateDocument () {
      this.fileStackUpload(
        async (newDoc) => {
          this.progressStart()

          try {
            const newDocument = (await ClientDocument.update({
              client_id: this.client.id,
              document_type: this.currentDoc.document_type,
              id: this.currentDoc.id,
              name: newDoc.filename,
              url: newDoc.url,
            })).data
            this.currentDoc = newDocument
            this.progressFinish()
          }
          catch (error) {
            this.captureSentryEvent(
              'Client Doc "Update"',
              {
                config: error.config,
                data: this.$data,
                details: error,
                newDocFromFilestackUpload: newDoc || null,
                props: this.$props,
                response: error.response,
              }
            )
            this.CError(error)
            this.requestFailure({ message: 'There was an issue updating the document' })
          }
        }
      )
    },

    validateEmail () {
      if (!this.email.value || !this.isValidEmail(this.email.value)) {
        this.$set(this.email, 'valid', false)
        return false
      }
      this.$set(this.email, 'valid', true)
      return true
    },

    viewDocument () {
      viewDocument(this.currentDoc.id)
    }
  },
}
</script>

<style lang="sass">
.ClientDoc

  &__close-icon
    padding: rem(9px)

  &__email-lbl
    align-items: center
    display: flex
    height: $input-height
</style>
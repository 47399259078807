var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientDoc column" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "row row--align-center" }, [
          _vm.currentDoc.created_at
            ? _c("i", { staticClass: "fa fa-check fa-15 fa-success" })
            : _vm._e(),
          !_vm.currentDoc.created_at
            ? _c("i", { staticClass: "fa fa-add fa-rotate-45 fa-20 fa-danger" })
            : _vm._e(),
          _c(
            "label",
            { staticClass: "fs-16 fw-med capitalize" },
            [_vm._t("default")],
            2
          )
        ]),
        _c("div", { staticClass: "row row--align-center row--justify-end" }, [
          _vm.currentDoc.id
            ? _c(
                "div",
                {
                  staticClass: "row row--align-center row--width-auto clickable"
                },
                [
                  _c("i", {
                    staticClass: "fa fa-view fa-15 fc-light mr-20",
                    attrs: { role: "button" },
                    on: { click: _vm.viewDocument }
                  }),
                  _c("i", {
                    staticClass: "fa fa-upload-2 fa-15 fc-light mr-20",
                    attrs: { role: "button" },
                    on: { click: _vm.updateDocument }
                  }),
                  _c("i", {
                    staticClass: "fa fa-mail fa-15 fc-light mr-20",
                    attrs: { role: "button" },
                    on: { click: _vm.showEmail }
                  }),
                  _c("i", {
                    staticClass: "fa fa-trash-can fa-15 fc-light mr-20",
                    attrs: { role: "button" },
                    on: { click: _vm.deleteDoc }
                  })
                ]
              )
            : _c("i", {
                staticClass: "fa fa-upload-2 fa-15 fc-light mr-20 clickable",
                on: { click: _vm.createNewDocument }
              }),
          _c(
            "time",
            {
              staticClass: "ClientDocs__doc-date",
              attrs: {
                datetime: _vm._f("datetime")(
                  _vm.currentDoc.updated_at,
                  "YYYY-MM-DD hh:mm:ssZ"
                )
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("datetime")(_vm.currentDoc.updated_at, "MM/DD/YYYY")
                  ) +
                  "\n      "
              )
            ]
          )
        ])
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          _vm.emailing
            ? _c("div", { staticClass: "row row--align-end mt-12" }, [
                _c("i", {
                  staticClass:
                    "ClientDoc__close-icon fa fa-add-circle fa-rotate-45 fa-20 fa-3 clickable",
                  attrs: { tabindex: "0" },
                  on: {
                    click: _vm.cancelEmailing,
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.cancelEmailing($event)
                    }
                  }
                }),
                _c(
                  "div",
                  { staticClass: "row row--align-end row--wrap" },
                  [
                    _c(
                      "label",
                      { staticClass: "ClientDoc__email-lbl fs-14 mr-9" },
                      [_vm._v("Who do you want to send this invoice to?")]
                    ),
                    _c(
                      "base-input",
                      {
                        attrs: {
                          instructions: _vm.instructions.EMAIL_INSTRUCTION,
                          label: true,
                          "label-for": "email",
                          placeholder: "email",
                          valid: _vm.email.valid
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.sendDocument($event)
                          }
                        },
                        model: {
                          value: _vm.email.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.email,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "email.value"
                        }
                      },
                      [_vm._v("\n          Email\n        ")]
                    ),
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white ml-6",
                        attrs: {
                          disabled:
                            (!_vm.email && !_vm.client.email) ||
                            _vm.buttonDisabled
                        },
                        on: { click: _vm.sendDocument }
                      },
                      [_vm._v("\n          Send\n        ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
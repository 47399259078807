var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientProcessingNotes column" },
    [
      _c(
        "div",
        { staticClass: "row row--align-center row--justify-between mb-22" },
        [
          _c("label", { staticClass: "fs-16 fw-med" }, [
            _vm._v("General Note")
          ]),
          !_vm.generalNotes.length
            ? _c(
                "base-button",
                {
                  staticClass: "bg-blue fc-white",
                  on: {
                    click: function($event) {
                      _vm.newGeneralNote = true
                    }
                  }
                },
                [_vm._v("\n      New Note\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.newGeneralNote
        ? _c(
            "div",
            { staticClass: "column" },
            [
              _c("base-create-processing-note", {
                attrs: {
                  "client-id": _vm.client.id,
                  "include-debtor": false,
                  "include-status": false,
                  type: "client"
                },
                on: {
                  cancel: function($event) {
                    _vm.newGeneralNote = false
                  },
                  created: _vm.getNotes
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("base-table", {
        attrs: {
          "column-headers": ["", "date", "note", "actions"],
          "which-table": "ClientNotes"
        },
        scopedSlots: _vm._u([
          {
            key: "rows",
            fn: function() {
              return _vm._l(_vm.generalNotes, function(note, index) {
                return _c("base-processing-note", {
                  key: "gennote-" + index,
                  attrs: { note: note },
                  on: { deleted: _vm.getNotes, edited: _vm.getNotes }
                })
              })
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "row row--align-center row--justify-between mb-22" },
        [
          _c("label", { staticClass: "fs-16 fw-med" }, [
            _vm._v("Specific Notes")
          ]),
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              on: {
                click: function($event) {
                  _vm.newSpecificNote = true
                }
              }
            },
            [_vm._v("\n      New Note\n    ")]
          )
        ],
        1
      ),
      _vm.newSpecificNote
        ? _c(
            "div",
            { staticClass: "column" },
            [
              _c("base-create-processing-note", {
                attrs: {
                  "client-id": _vm.client.id,
                  "include-debtor": true,
                  "include-status": false,
                  type: "client"
                },
                on: {
                  cancel: function($event) {
                    _vm.newSpecificNote = false
                  },
                  created: _vm.getNotes
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("base-table", {
        attrs: {
          "column-headers": ["", "date", "debtor", "note", "actions"],
          "which-table": "ClientNotes"
        },
        scopedSlots: _vm._u([
          {
            key: "rows",
            fn: function() {
              return _vm._l(_vm.specificNotes, function(note, index) {
                return _c("base-processing-note", {
                  key: "specnote-" + index,
                  attrs: { note: note },
                  on: { deleted: _vm.getNotes, edited: _vm.getNotes }
                })
              })
            },
            proxy: true
          }
        ])
      }),
      _c("base-pagination", {
        staticClass: "mt-22",
        attrs: {
          "item-count": _vm.notesCount,
          page: _vm.notesPage,
          "request-limit": _vm.requestLimit
        },
        on: { paginate: _vm.getSpecificNotes }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="ClientProcessingNotes column">
    <div class="row row--align-center row--justify-between mb-22">
      <label class="fs-16 fw-med">General Note</label>
      <!-- Only 1 general note at a time -->
      <base-button
        v-if="!generalNotes.length"
        @click="newGeneralNote = true"
        class="bg-blue fc-white"
      >
        New Note
      </base-button>
    </div>

    <!-- NEW GENERAL NOTE -->
    <div
      v-if="newGeneralNote"
      class="column"
    >
      <base-create-processing-note
        @cancel="newGeneralNote = false"
        @created="getNotes"
        :client-id="client.id"
        :include-debtor="false"
        :include-status="false"
        :type="'client'"
      />
    </div>

    <!-- GENERAL NOTES -->
    <!-- '' stands for "status," but "status" is too wide for the status dot column -->
    <base-table
      :column-headers="['', 'date', 'note', 'actions']"
      :which-table="'ClientNotes'"
    >
      <template v-slot:rows>
        <base-processing-note
          v-for="(note, index) in generalNotes"
          :key="`gennote-${index}`"
          @deleted="getNotes"
          @edited="getNotes"
          :note="note"
        />
      </template>
    </base-table>

    <div class="row row--align-center row--justify-between mb-22">
      <label class="fs-16 fw-med">Specific Notes</label>
      <base-button
        @click="newSpecificNote = true"
        class="bg-blue fc-white"
      >
        New Note
      </base-button>
    </div>

    <!-- NEW SPECIFIC NOTE -->
    <div
      v-if="newSpecificNote"
      class="column"
    >
      <base-create-processing-note
        @cancel="newSpecificNote = false"
        @created="getNotes"
        :client-id="client.id"
        :include-debtor="true"
        :include-status="false"
        :type="'client'"
      />
    </div>

    <!-- SPECIFIC NOTES -->
    <!-- '' stands for "status," but "status" is too wide for the status dot column -->
    <base-table
      :column-headers="['', 'date', 'debtor', 'note', 'actions']"
      :which-table="'ClientNotes'"
    >
      <template v-slot:rows>
        <base-processing-note
          v-for="(note, index) in specificNotes"
          :key="`specnote-${index}`"
          @deleted="getNotes"
          @edited="getNotes"
          :note="note"
        />
      </template>
    </base-table>

    <base-pagination
      @paginate="getSpecificNotes"
      class="mt-22"
      :item-count="notesCount"
      :page="notesPage"
      :request-limit="requestLimit"
    />
  </div>
</template>

<script>
// Helpers
import { ProcessingNotes } from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseCreateProcessingNote from '../../../components/base-create-processing-note.vue'
import BasePagination from '../../../components/base-pagination.vue'
import BaseProcessingNote from '../../../components/base-processing-note.vue'
import BaseTable from '../../../components/base-table.vue'

export default {
  name: 'ClientProcessingNotes',

  components: {
    BaseButton,
    BaseCreateProcessingNote,
    BasePagination,
    BaseProcessingNote,
    BaseTable
  },

  async created () {
    await this.getNotes()
  },

  data () {
    return {
      generalNotes: [],
      newGeneralNote: false,
      newSpecificNote: false,
      notes: null,
      notesCount: 0,
      notesPage: 1,
      requestLimit: 10,
      specificNotes: [],
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    async getGeneralNotes () {
      this.progressStart()
      this.newGeneralNote = false
      this.newSpecificNote = false

      try {
        this.generalNotes = (await ProcessingNotes.queryList({
          client_id: this.client.id,
          debtor_id: 'null',
          offset: (this.notesPage - 1) * this.requestLimit,
          sort_by: 'status',
          sort_direction: 'ASC'
        }, this.requestLimit)).data.rows

        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client "Get General Notes" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s notes' })
      }
    },

    async getNotes () {
      this.getGeneralNotes()
      this.getSpecificNotes()
    },

    async getSpecificNotes (page = null) {
      if (page) this.notesPage = page

      this.progressStart()
      this.newGeneralNote = false
      this.newSpecificNote = false

      try {
        const notesData = (await ProcessingNotes.queryList({
          client_id: this.client.id,
          // ne___ === NOT equal
          ne___debtor_id: 'null',
          offset: (this.notesPage - 1) * this.requestLimit,
          sort_by: 'status',
          sort_direction: 'ASC'
        }, this.requestLimit)).data

        this.notesCount = notesData.count
        this.specificNotes = notesData.rows

        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client "Get Specific Note" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s notes' })
      }
    },
  }
}
</script>

<style lang="sass">
.ClientProcessingNotes
  margin-top: rem(42px)
  padding: 0 rem(29px) 2.5rem

  .Table
    margin-bottom: rem(42px)

  .BaseProcessingNote
    border-bottom: $border

    &:last-child
      border-bottom: none
</style>